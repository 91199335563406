body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

/* Encabezado */
header {
    background-color: #0074D9;
    color: #FFF;
    padding: 20px;
    text-align: center;
}

/* Barra de navegación */
nav {
    background-color: #DDD;
    padding: 10px;
    text-align: center;
}

nav a {
    color: #0074D9;
    text-decoration: none;
    padding: 10px;
    font-weight: bold;
}

/* Sección de contenido */
section {
    padding: 20px;
    text-align: justify;
    line-height: 1.5em;
    max-width: 800px;
    margin: auto;
}

/* Fondo con imagen responsive */
.bg-image {
    background-image: url('https://www.cerrejon.com/sites/default/files/inline-images/WhatsApp%20Image%202022-11-30%20at%208.57.31%20PM.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: auto;
}

.bg-image::after {
    content: "";
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

/* Estilos para dispositivos pequeños */
@media screen and (max-width: 600px) {
    header {
        padding: 10px;
        font-size: 18px;
    }

    nav a {
        font-size: 16px;
    }

    section {
        padding: 10px;
        font-size: 16px;
    }
}
.ad-banner {
    background-color: #FFF;
    border: 1px solid #CCC;
    box-shadow: 0 0 10px #CCC;
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    text-align: center;
 }
 
 .ad-banner img {
    max-width: 100%;
 }